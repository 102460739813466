import "./styles.scss";

import React, { useEffect } from "react";
import { navigate } from "gatsby";
import classnames from "classnames";

import SideHeader from "components/SideHeader";
import SearchBar from "components/SearchBar";

import SpainIcon from "icons/investments/spain.svg";
import CyprusIcon from "icons/investments/cyprus.svg";
import MountainsIcon from "icons/investments/mountains.svg";
import SeaIcon from "icons/investments/sea.svg";
import MazuryIcon from "icons/investments/mazury.svg";
import { useSearcher } from "../../../context/searcher";

const mainClass = "search-engine";

const shortcuts__items = [
  {
    key: "spain",
    icon: <SpainIcon />,
    label: "Hiszpania",
  },
  // {
  //   key: "city",
  //   icon: <CyprusIcon />,
  //   label: "Miasto",
  // },
  {
    key: "mountains",
    icon: <MountainsIcon />,
    label: "Góry",
  },
  {
    key: "sea",
    icon: <SeaIcon />,
    label: "Morze",
  },
  {
    key: "lake",
    icon: <MazuryIcon />,
    label: "Mazury",
  },
];

const SearchEngine = ({ view_type, setViewType, provider_data }) => {
  const {
    state: { view, category },
    setPriceMin,
    setPriceMax,
    setCategory,
    setInvestment,
    setMainTitle,
    setSurfacemin,
    setSurfacemax,
    setAmenities,
    setContactLength,
    setVacationsDays,
    setBackPercentage,
    setPage,
  } = useSearcher();

  useEffect(() => {
    if (view === "map") {
      setViewType("map");
    }
  }, []);

  const navigateHandler = (key) => {
    setPriceMin(undefined);
    setPriceMax(undefined);
    setSurfacemin(undefined);
    setSurfacemax(undefined);
    setInvestment(undefined);
    setMainTitle(undefined);
    setAmenities(undefined);
    setBackPercentage(undefined);
    setContactLength(undefined);
    setVacationsDays(undefined);
    setPage(undefined);
    setCategory(key);
  };

  return (
    <div className={mainClass}>
      <div className="container">
        <SideHeader header="Znajdź" text="wymarzony | apartament" />
        <div className={`${mainClass}__content`}>
          <div className={`${mainClass}__content__shortcuts`}>
            <h3>Na skróty:</h3>
            <div className={`${mainClass}__content__shortcuts__items`}>
              {shortcuts__items?.map(({ icon, label, key }, index) => (
                <div
                  key={index}
                  onClick={() => navigateHandler(key)}
                  className={classnames(
                    `${mainClass}__content__shortcuts__items__item`,
                    {
                      [`${mainClass}__content__shortcuts__items__item--active`]:
                        category?.includes(key),
                    }
                  )}
                >
                  {icon}
                  <span>{label}</span>
                </div>
              ))}
            </div>
          </div>
          <SearchBar
            variant="static"
            view_type={view_type}
            setViewType={setViewType}
            provider_data={provider_data}
          />
        </div>
      </div>
    </div>
  );
};

export default SearchEngine;
