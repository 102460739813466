import "./styles.scss";

import React from "react";

import InvestmentListItem from "components/InvestmentListItem";

const mainClass = "search-result__list";

const List = ({ data }) => {
  return (
    <div className={mainClass}>
      {data?.map((props, index) => (
        <InvestmentListItem key={index} {...props} popup_form />
      ))}
    </div>
  );
};

export default List;
