import "./styles.scss";

import React from "react";

import InvestmentGridItem from "components/InvestmentGridItem";

const mainClass = "search-result__grid";

const Grid = ({ data }) => {
  return (
    <div className={mainClass}>
      {data?.map((props, index) => (
        <InvestmentGridItem key={index} {...props} variant="blue" popup_form />
      ))}
    </div>
  );
};

export default Grid;
