import "./styles.scss";

import React from "react";

import LocationMap from "components/LocationMap";

const mainClass = "search-result__map";

const Map = ({ data }) => {
  return (
    <div className={mainClass}>
      <LocationMap
        latitude={51.9189046}
        longitude={19.1343786}
        zoom={6}
        markers={data}
      />
    </div>
  );
};

export default Map;
