import "./styles.scss";

import React from "react";
import Select from "react-select";

import Pagination from "components/Pagination";
import Grid from "./components/Grid";
import List from "./components/List";
import Map from "./components/Map";

import investmentTileParser from "utils/investmentTileParser";

import { useSearcher } from "context/searcher";

const mainClass = "search-result";

const result_view_render = (type, data, data_for_map) => {
  switch (type) {
    case "grid":
      return <Grid data={data} />;
    case "list":
      return <List data={data} />;
    case "map":
      return <Map data={data_for_map} />;
    default:
      return null;
  }
};

const sort_options = [
  {
    value: "price",
    label: "Cenie: od najniższej",
  },
  {
    value: "-price",
    label: "Cenie: od najwyższej",
  },
  {
    value: "date",
    label: "Najstarsze",
  },
  {
    value: "-date",
    label: "Najnowsze",
  },
  {
    value: "popularForSorting",
    label: "Popularne",
  },
];

const selectStyles = {
  control: (provided) => ({
    ...provided,
    background: "transparent",
    border: "none",
    outline: "none",
    boxShadow: "none",
    color: "#fff",
    width: "220px",
    fontWeight: 700,
    fontSize: 15,
  }),
  indicatorSeparator: () => ({
    display: "none",
  }),
};

const SearchResult = ({ router, view_type }) => {
  const {
    state: { investments, sort, page },
    setSort,
    setPage,
  } = useSearcher();

  const investments_data = investmentTileParser(investments?.data);
  const investments_data_for_map = investmentTileParser(
    investments?.data_for_map
  );

  return (
    <div className={mainClass}>
      <div className="container">
        <div className={`${mainClass}__sort`}>
          <div className={`${mainClass}__sort__content`}>
            <span>Sortuj po:</span>
            <Select
              placeholder="Sortowanie"
              name="sortSelect"
              isOptionSelected={(option, selectValue) => {
                selectValue.some((i) => i === option);
              }}
              defaultValue={sort_options.find(({ value }) => value === sort)}
              isOptionDisabled={({ value }) => value === sort}
              id="sortSelect"
              styles={selectStyles}
              options={sort_options}
              onChange={({ value }) => {
                setSort(value);
              }}
            />
          </div>
        </div>
        {result_view_render(
          view_type,
          investments_data,
          investments_data_for_map
        )}
        {view_type !== "map" && (
          <Pagination
            page={page}
            router={router}
            count={investments?.meta?.count}
            per_page={9}
            setPage={setPage}
          />
        )}
      </div>
    </div>
  );
};

export default SearchResult;
