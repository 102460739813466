import "styles/pages/home-page.scss";

import React, { useState, useEffect } from "react";
import { graphql } from "gatsby";

import Layout from "../components/Layout";

import { SearchEngine, SearchResult } from "../page_components/searcher";

import SearcherProvider from "context/searcher";

const Searcher = ({ data, location }) => {
  const [view_type, setViewType] = useState("grid");

  const all_investments = data?.allInvestments?.nodes;

  const seo = data.investmentPage.seo;
  const schema = JSON.parse(data.investmentPage.seo.schema.raw);

  useEffect(() => {
    function handleResize() {
      if (window.innerWidth <= 1160) {
        if (view_type !== "grid" && view_type !== "map") {
          setViewType("grid");
        }
      }
    }

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  });

  return (
    <Layout
      blue_header
      seo={{ title: seo?.title, desc: seo?.desc, schema }}
      location="Inwestycje"
    >
      <SearcherProvider values={{ all_investments }}>
        <SearchEngine view_type={view_type} setViewType={setViewType} />
        <SearchResult view_type={view_type} router={location} />
      </SearcherProvider>
    </Layout>
  );
};

export default Searcher;

export const query = graphql`
  query investments {
    allInvestments: allWpInvestment(
      filter: { investments: { hideInvestment: { eq: true } } }
      sort: { fields: investments___position }
    ) {
      nodes {
        title
        id
        uri
        date
        slug
        investments {
          category
          popularForSorting
          backPercentage
          subdivisionModel
          ready
          contactLength
          deadline
          investLogo {
            sourceUrl
            altText
          }
          mainImg {
            sourceUrl
          }
          mainTitle
          priceMin
          priceMax
          currency
          vacationsDaysCustom
          subtitle
          shortLocation
          surfacemin
          locationLng
          locationLat
          parking
          spa
          restaurant
          skiRoom
          reception
          gym
          conferenceRoom
          bikeRoom
          bar
          poolIn
          poolOut
          terrace
          labelState
          labelText
        }
      }
    }
    investmentPage: wpPage(id: { eq: "cG9zdDoyNzI=" }) {
      seo {
        title
        metaDesc
        schema {
          raw
        }
        opengraphTitle
        opengraphDescription
        opengraphType
        opengraphUrl
        opengraphImage {
          sourceUrl
        }
      }
    }
  }
`;
